import {Section} from "../Section/Section";
import {SectionTitle} from "../SectionTitle/SectionTitle";
import styles from "./VideoGallery.module.css"
import {useEffect, useState} from "react";

export function VideoGallery() {
  const [videoSource, setVideoSource] = useState(null);
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    fetch('/videos/galleryVideos.json')
      .then(response => response.json())
      .then(data => setVideos(() => data))
      .catch(error => console.error('Error fetching the videos:', error));
  }, []);

  return (
    <Section>
      <SectionTitle>VideOs</SectionTitle>
      <VideoList>
        {videos.map((video, i) =>
          <Video thumbnail={video.thumbnail}
                 title={video.title}
                 description={video.description}
                 onClick={() => setVideoSource(() => video.src)}
                 key={i} />
        )}
      </VideoList>

      {videoSource && <VideoModal src={videoSource} onClose={() => setVideoSource(() => null)}/> }
    </Section>
  )
}

function VideoList({children}) {
  return (
    <ul className={styles.videoList}>
      {children}
    </ul>
  )
}

function Video({thumbnail, title, description, onClick}) {
  return (
    <li>
      <div className={styles.videoCard} onClick={onClick}>
        <img className={styles.videoCardImage} src={thumbnail} alt="Video thumbnail" />
        <div className={styles.playCircleContainer}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" className={styles.playCircle}>
            <circle cx="12" cy="12" r="10"></circle>
            <polygon points="10 8 16 12 10 16 10 8"></polygon>
          </svg>
        </div>
        <div className={styles.videoCardOverlay}>
          <div className={styles.cardHeader}>
            <svg className={styles.cardArc} xmlns="http://www.w3.org/2000/svg">
              <path></path>
            </svg>
            <h3 className={styles.cardTitle}>{title}</h3>
          </div>
          <p className={styles.cardDescription}>{description}</p>
        </div>
      </div>
    </li>
  )
}

function VideoModal({src, onClose}) {
  const [canPlay, setCanPlay] = useState(false);

  return (
    <div className={`${styles.videoPopup} ${canPlay ? styles.videoPopupVisible : ''}`}  onClick={onClose}>
      <video controls autoPlay playsInline muted onCanPlay={() => setCanPlay(true)} onClick={(e) => e.stopPropagation()}>
        <source src={src} type="video/mp4" />
      </video>
    </div>
  )
}