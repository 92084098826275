import {Section} from "../Section/Section";
import {SectionTitle} from "../SectionTitle/SectionTitle";
import styles from "./LocationSection.module.css"

export function LocationSection() {
  return (
    <Section>
      <SectionTitle>lOcatiOn</SectionTitle>
      <p className={styles.title}>You can find us a 4 Marlborough Road, Southport, PR9 0RA</p>
      <iframe
        className={styles.map}
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2364.867119557655!2d-2.9949708841501064!3d53.64934148004362!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487b3ec701472713%3A0x99aa819c8728fab7!2sBoho+Brides!5e0!3m2!1sen!2suk!4v1546421544317"
        allowFullScreen=""
        width="100%"
        height="470"
        frameBorder="0">
      </iframe>
    </Section>
  )
}