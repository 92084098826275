import styles from './Button.module.css'

export function Button({children, type, disabled, onClick}) {
  const typeStyle = type === 'cta' ? styles.cta : styles.secondary;
  const disabledStyle = disabled ? styles.disabled : "";
  return (
    <button onClick={onClick}
            className={`${styles.btn} ${typeStyle} ${disabledStyle}`}
            disabled={disabled}>{children}</button>
  )
}