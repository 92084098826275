import {ServiceCard} from "../ServiceCard/ServiceCard";
import styles from './PricingCard.module.css'

export function PricingCard() {
  const prices = [
    { price: '£290', condition: 'Monday - Thursday' },
    { price: '£330', condition: 'Friday - Sunday' },
  ]

  const keyPoints = [
    '£20 discount per extra vehicle',
    '4 hours per hire from pickup',
    '£30 extra for each additional hour (6 hours max)',
  ]

  const smallStatement = "Prices stated are within a 20-mile radius of Southport.\n" +
    "We are happy to do a few journeys within reason to help on your big day. We will also stick around for photographs after your event."

  return (
    <ServiceCard title="Pricing">
      <div className={styles.contentContainer}>
        <div className={styles.priceList}>
          {prices.map((price, index) => (
            <div key={index} className={styles.priceContainer}>
              <span className={`${styles.smallText} ${styles.daysTextContainer}`}>{price.condition}</span>
              <div>
                <span className={styles.priceText}>{price.price}</span>
                <span className={styles.smallText}>per vehicle</span>
              </div>
            </div>
          ))}
        </div>
        <ul className={styles.infoList}>
          {keyPoints.map((text, index) =>
            <li key={index}>{text}</li>
          )}
        </ul>
        <div className={styles.statementTextContainer}>
          <span className={styles.smallText}>{smallStatement}</span>
        </div>
      </div>
    </ServiceCard>
  )
}