import './App.css';
import {Header} from "./Components/Header/Header";
import {Hero} from "./Components/Hero/Hero";
import {OurServices} from "./Components/OurServices/OurServices";
import {About} from "./Components/About/About";
import {PhotoGallerySection} from "./Components/PhotoGallery/PhotoGallerySection";
import {VideoGallery} from "./Components/VideoGallery/VideoGallery";
import {Enquire} from "./Components/Enquire/Enquire";
import {useEffect, useRef, useState} from "react";
import {FaqSection} from "./Components/FAQs/FaqSection";
import {LocationSection} from "./Components/Location/LocationSection";
import {Section} from "./Components/Section/Section";

function App() {
  const [photos, setPhotos] = useState([]);
  const enquiryRef = useRef();

  const styles = {
    background: "url('/images/bb-hero-01.jpg') center 0 no-repeat"
  }

  useEffect(() => {
    fetch('/images/galleryPhotos.json')
      .then(response => response.json())
      .then(data => setPhotos(() => data))
      .catch(error => console.error('Error fetching the photos:', error));
  }, []);

  function scrollToEnquiry() {
    enquiryRef.current.scrollIntoView({behavior: 'smooth'});
  }

  const currentYear = new Date(Date.now()).getFullYear();

  return (
    <div className="App" style={styles}>
      <Header onEnquiryClick={scrollToEnquiry} />
      <Hero onEnquiryClick={scrollToEnquiry}/>
      <OurServices/>
      <About/>
      <PhotoGallerySection photos={photos}/>
      <VideoGallery/>
      <Enquire titleRef={enquiryRef} vehiclePhotos={photos} />
      <FaqSection />
      <LocationSection />
      <Section>
        <p className='copyright'>All Rights Reserved ©{currentYear} Boho Brides</p>
      </Section>
    </div>
  );
}

export default App;
