import {SectionTitle} from "../SectionTitle/SectionTitle";
import styles from "./PhotoGallery.module.css"

export function PhotoGallerySection({photos}) {
  return (
    <section className={styles.container}>
      <SectionTitle>gAllEry</SectionTitle>
      <PhotoGallery photos={photos} />
    </section>
  )
}

export function PhotoGallery({photos, maxWidth = 350}) {
  const imageStyles = {
    display: 'inline',
    padding: '4px',
    maxWidth: `${maxWidth}px`,
    width: '75vw',
    aspectRatio: '1 / 1',
    objectFit: 'cover'
  }

  return (
    <div className={styles.gallery}>
      {photos.map((photo, i) => <img style={imageStyles} src={photo.src} key={i} alt={photo.alt} />)}
    </div>
  )
}