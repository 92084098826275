import {Section} from "../Section/Section";
import {Button} from "../Button/Button";
import styles from "./Hero.module.css"

export function Hero({onEnquiryClick}) {
  return (
    <Section>
      <h1 className={styles.header}>classic VW hire for any occasion</h1>
      <div className={styles.buttonContainer}>
        <Button onClick={onEnquiryClick} type="cta">Enquire About Hiring</Button>
      </div>
    </Section>
  )
}