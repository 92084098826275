import {Section} from "../Section/Section";
import {SectionTitle} from "../SectionTitle/SectionTitle";
import styles from "./FaqSection.module.css"

export function FaqSection() {
  const containerStyle = {
    background: 'url(/images/03.jpg) center center repeat'
  }

  const questionAnswers = [
    {
      question: "Can you pickup or dropoff guests at more than one location?",
      answer: "Yes, we can stop off at multiple postcodes to pickup all of your guests up to the vehicle capacity."
    },
    {
      question: "What side is the campervan sliding door on?",
      answer: "Most of our campervans are right hand drive, but we will always make sure that you can enter and exit the vehicle safely from the pavement kerbside."
    },
    {
      question: "Can I drive your classic vehicle?",
      answer: "No, but all our classic vehicles are chauffeur driven by our drivers."
    },
    {
      question: "Are the classic vehicles restored?",
      answer: "All of our classic vehicles are fully restored and reupholstered with weddings in mind."
    },
    {
      question: "Where else does your hire cover?",
      answer: "We also cover the NorthWest / Lancashire / Cheshire / Lake District / Liverpool / Cumbria / Manchester / North Wales / Anglesey"
    }
  ]

  return (
    <div style={containerStyle} className={styles.container}>
      <Section>
        <SectionTitle>faQ</SectionTitle>
        {questionAnswers.map((qa, index) => <QuestionAnswer key={index} questionAnswer={qa} />)}
      </Section>
    </div>
  )
}

function QuestionAnswer({questionAnswer}) {
  return (
    <div className={styles.questionAnswer}>
      <p className={styles.question}>{questionAnswer.question}</p>
      <p className={styles.answer}>{questionAnswer.answer}</p>
    </div>
  )
}