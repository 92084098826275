import {Section} from "../Section/Section";
import {SectionTitle} from "../SectionTitle/SectionTitle";
import {PricingCard} from "../PricingCard/PricingCard";
import {ProvideCard} from "../ProvideCard/ProvideCard";
import styles from "./OurServices.module.css"

export function OurServices() {
  return (
    <Section>
      <SectionTitle>oUr seRvices</SectionTitle>
      <div className={styles.sectionContainer}>
        <PricingCard />
        <ProvideCard />
      </div>
    </Section>
  )
}