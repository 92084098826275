import {Section} from "../Section/Section";
import {SectionTitle} from "../SectionTitle/SectionTitle";
import styles from "./About.module.css"

export function About() {
  const containerStyle = {
    background: '#70615c url(/images/bb-about-hero.jpg) center center no-repeat'
  }

  return (
    <div className={styles.container} style={containerStyle}>
      <Section>
        <SectionTitle>AbOUt</SectionTitle>
        <p className={styles.paragraph}>Here at Boho Brides we pride ourselves on offering classic vehicles that truly represent the vintage VW
          scene. Myself and my drivers are all VW enthusiasts and treasure our vehicles for what they are.</p>

        <p className={styles.paragraph}>Although many millions of Bugs, Buses, and Campervans left Volkswagen's
          factories over the years, each and everyone of them is unique and have a charm all of their own.</p>

        <div className={styles.spacer}></div>

        <p className={styles.paragraph}>Boho Brides was started through a love of our vehicles, and wanting to share them with people who truly
          appreciate them as much as we do. For over 25 years before Boho Brides Wedding Hire was started, I have been
          restoring all types of VW's and have a passion for these classic vehicles.</p>

        <p className={styles.paragraph}>We are based in Southport, Merseyside but since we started Boho Brides in 2012 we have travelled all over
          North West England, the Midlands and Wales, driving many happy couples to their weddings in style by one of
          our smartly dressed chauffeurs, who do everything they can to make your day extra special.</p>
      </Section>
    </div>
  )
}