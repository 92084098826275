import styles from "./Textbox.module.css"
import {useState} from "react";

export function Textbox({placeholder, required = false, value, setValue}) {
  const [touched, setTouched] = useState(false);
  const invalid = touched && required && !value;

  return (
    <div className={styles.inputContainer}>
      <label className={`${styles.label} ${value ? styles.activeLabel : ''}`}>{placeholder}{required ? '*' : ''}</label>
      <input
        className={`${styles.textBox} ${invalid && touched ? styles.invalid : ''}`}
        type="text"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onBlur={() => setTouched(true)} />
      {invalid && <small className={styles.errorText}>You must provide a {placeholder}</small>}
    </div>
  )
}