import {Textbox} from "../FormElements/Textbox";
import {useEffect, useState} from "react";
import styles from "./EnquiryForm.module.css"
import {VehicleButton} from "../VehicleButton/VehicleButton";
import {TextArea} from "../FormElements/TextArea";
import {Button} from "../Button/Button";
import {DropdownSelect} from "../FormElements/DropdownSelect";
import {PhotoGallery} from "../PhotoGallery/PhotoGallerySection";

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  additionalInfo: "",
  eventType: "",
  eventDate: "",
  pickupPostcode: "",
  dropoffPostcode: "",
  passengers: "1",
  vehicles: []
}

export const EnquiryForm = ({vehiclePhotos}) => {
  const [form, setForm] = useState(initialState);
  const [vehicles, setVehicles] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState(null);

  const baseUrl = "https://bohobrides.azurewebsites.net/";
  //const baseUrl = "https://localhost:5001/"

  const handleInputChange = (field, value) => {
    setForm((state) => {
      return {...state,
      [field]: value,
    }});
  };

  useEffect(() => {
    fetch('/formVehicles.json')
      .then(response => response.json())
      .then(data => setVehicles(data))
      .catch(error => console.error('Error fetching the vehicles:', error));
  }, []);

  function formValid() {
    if (!form.firstName) return false;
    if (!form.lastName) return false;
    if (!form.email) return false;
    return true;
  }

  function toggleVehicle(vehicle) {
    setForm(curr => {
      const updatedVehicles = [...curr.vehicles];
      const index = updatedVehicles.indexOf(vehicle.name);
      setSelectedVehicle(vehicle.id);

      if (index === -1) {
        updatedVehicles.push(vehicle.name);
      } else {
        updatedVehicles.splice(index, 1);
      }

      return {
        ...curr,
        vehicles: updatedVehicles
      };
    });
  }

  function submitEnquiry() {
    fetch(baseUrl + 'api/enquiries', {
      method: 'POST',
      body: JSON.stringify(form),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    })
      .then((response) => response.json())
      .then(() => {
        alert("Thank you for contacting Boho Brides. We will contact you shortly.");
      })
      .catch(() => {
        alert("There was an issue contacting the server, please try again later.");
      });
  }

  function clearForm() {
    setForm(initialState);
    setSelectedVehicle(null);
  }

  function isVehicleSelected(vehicle) {
    return form.vehicles.indexOf(vehicle.name) !== -1;
  }

  const passengerOptions = [
    {displayValue: "1", value: "1"},
    {displayValue: "2", value: "2"},
    {displayValue: "3", value: "3"},
    {displayValue: "4", value: "4"},
    {displayValue: "5", value: "5"},
    {displayValue: "6", value: "6"},
    {displayValue: "7+", value: "7+"}
  ]

  const selectedVehiclePhotos = vehiclePhotos.filter(photoData => photoData.id === selectedVehicle);

  return (
    <div>
      <h3>Tell Us About You</h3>
      <div className={styles.form}>
        <Textbox placeholder="First Name"
                 required={true}
                 value={form.firstName}
                 setValue={(val) => handleInputChange('firstName', val)}/>
        <Textbox placeholder="Last Name"
                 required={true}
                 value={form.lastName}
                 setValue={(val) => handleInputChange('lastName', val)}/>
        <Textbox placeholder="Email"
                 required={true}
                 value={form.email}
                 setValue={(val) => handleInputChange('email', val)}/>
        <Textbox placeholder="Phone"
                 required={false}
                 value={form.phone}
                 setValue={(val) => handleInputChange('phone', val)}/>
      </div>

      <h3>Tell Us About Your Event</h3>
      <div className={styles.form}>
        <Textbox placeholder="Event Type"
                 required={false}
                 value={form.eventType}
                 setValue={(val) => handleInputChange('eventType', val)}/>
        <Textbox placeholder="Event Date"
                 required={false}
                 value={form.eventDate}
                 setValue={(val) => handleInputChange('eventDate', val)}/>
        <Textbox placeholder="Pickup Postcode"
                 required={false}
                 value={form.pickupPostcode}
                 setValue={(val) => handleInputChange('pickupPostcode', val)}/>
        <Textbox placeholder="Dropoff Postcode"
                 required={false}
                 value={form.dropoffPostcode}
                 setValue={(val) => handleInputChange('dropoffPostcode', val)}/>
        <DropdownSelect placeholder="Passengers"
                        value={form.passengers}
                        setValue={(val) => handleInputChange('passengers', val)}
                        options={passengerOptions} />
      </div>

      <h3>View and Pick Vehicle(s) of Interest</h3>
      <div className={`${styles.form} ${styles.vehicleSection}`}>
        {vehicles.map((vehicle) =>
          <VehicleButton key={vehicle.id}
                         onClick={() => toggleVehicle(vehicle)}
                         selected={isVehicleSelected(vehicle)}
                         name={vehicle.name}
                         description={vehicle.description}
                         src={vehicle.src} />
        )}
      </div>
      <PhotoGallery photos={selectedVehiclePhotos} maxWidth={250} />

      <TextArea placeholder="Additional Info"
                minHeight={120}
                value={form.additionalInfo}
                setValue={(val) => handleInputChange('additionalInfo', val)}/>

      <div className={styles.buttonContainer}>
        <Button onClick={clearForm}>Clear</Button>
        <Button onClick={submitEnquiry} type="cta" disabled={!formValid()}>Send Enquiry</Button>
      </div>
    </div>
  )
}