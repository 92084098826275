import {useState} from "react";
import styles from "./Textbox.module.css";

export function TextArea({placeholder, required, minHeight = 0, value, setValue}) {
  const [touched, setTouched] = useState(false);
  const invalid = touched && required && !value;

  const style = {
    minHeight: `${minHeight}px`
  }

  return (
    <div className={styles.inputContainer}>
      <label className={`${styles.label} ${value ? styles.activeLabel : ''}`}>{placeholder}{required ? '*' : ''}</label>
      <textarea
        className={`${styles.textBox} ${invalid && touched ? styles.invalid : ''}`}
        style={style}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onBlur={() => setTouched(true)} />
      {invalid && <small className={styles.errorText}>You must provide a {placeholder}</small>}
    </div>
  )
}