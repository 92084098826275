import {Section} from "../Section/Section";
import {SectionTitle} from "../SectionTitle/SectionTitle";
import {EnquiryForm} from "./EnquiryForm";

export function Enquire({titleRef, vehiclePhotos}) {
  return (
    <Section sectionRef={titleRef}>
      <SectionTitle>enqUire abOUt hiRing</SectionTitle>
      <EnquiryForm vehiclePhotos={vehiclePhotos} />
    </Section>
  )
}