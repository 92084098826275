import styles from './ServiceCard.module.css'

export function ServiceCard({title, children}) {
  return (
      <div className={styles.container}>
        <div className={styles.header}>
          <span>{title}</span>
        </div>
        <div className={styles.mainCard}>
          {children}
        </div>
      </div>
  )
}