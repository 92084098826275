import {ServiceCard} from "../ServiceCard/ServiceCard";
import styles from "./ProvideCard.module.css"

export function ProvideCard() {
  const sections = [
    { header: "Our Drivers", content: "All our drivers are friendly, fun and fully suited" },
    { header: "Safety", content: "All our classics are fitted with seatbelts" },
    { header: "ISOFix", content: "For the little ones under 3, our vehicles Destiny, Angel and Sami\n" +
        "have ISOFix fittings (car seats must be supplied by yourself)" },
    { header: "Passengers", content: "We are insured for 7 passengers in all our VW Campervans, and 3\n" +
        "passengers in out VW Beetles" },
    { header: "Just Married Sign", content: 'A "Just Married" sign to display after your event' },
    { header: "Bows and Ribbons", content: "Our vehicles are fully dressed in flower, bows and ribbons of your colour choice" },
    { header: "Photographs", content: "We are MORE than happy to stick around for photographs after your big day." }
  ]

  return (
    <ServiceCard title="What We Provide">
      <div className={styles.contentContainer}>
        {sections.map((section, index) => (
          <div key={index} className={styles.sectionContainer}>
            <p className={styles.headerText}>{section.header}</p>
            <p className={styles.contentText}>{section.content}</p>
          </div>
        ))}
      </div>
    </ServiceCard>
  )
}