import styles from "./Textbox.module.css";
import {useState} from "react";

export function DropdownSelect({placeholder, value, setValue, options}) {
  const [listOpen, setListOpen] = useState(false);

  const toggleList = () => {
    setListOpen(curr => !curr);
  }

  const handleOptionSelect = (value) => {
    setValue(value);
    setListOpen(false);
  }

  const getSelectedOption = () => {
    if (options.length === 0) return;
    const index = options.findIndex(opt => opt.value === value);
    if (index === -1) {
      return options[0];
    }
    return options[index];
  }

  const selectedOption = getSelectedOption()?.displayValue ?? "";

  return (
    <div className={styles.dropdownContainer}>
      <label className={`${styles.label} ${styles.activeLabel}`}>{placeholder}</label>
      <div className={styles.dropdownInnerContainer} onClick={toggleList}>
        <span className={styles.selectedOption}>{selectedOption}</span>
        <span className={styles.dropdownArrow}>{listOpen ? '▲' : "▼"}</span>
      </div>

      {listOpen && (
        <ul className={styles.dropdownList}>
          {options.map(opt =>
            <li key={opt.value} onClick={() => handleOptionSelect(opt.value)}>{opt.displayValue}</li>
          )}
        </ul>
      )}
    </div>
  )
}